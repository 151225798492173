<template>
  <div>
    <!-- 添加样式 -->
    <el-dialog
      :title="addStyleDialogVisible.title"
      :visible.sync="addStyleDialogVisible.status"
      width="26.4vw"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        :model="form"
        :rules="materialLibraryFormRules"
        ref="ruleFormRef"
        class="form"
        label-position="left"
        label-width="6vw"
      >
        <el-form-item label="样式名称：" prop="name">
          <el-input v-model.trim="form.name" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item label="样式说明：">
          <el-input v-model="form.description" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item
          label="选择分类："
          label-position="left"
          prop="categoryId"
        >
          <el-select v-model="form.categoryId" placeholder="默认为系统分类">
            <el-option
              v-for="item in categoryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="样式文件："
          v-if="addStyleDialogVisible.title == '导入本地样式'"
          prop="localFileName"
        >
          <el-input
            v-model="form.localFileName"
            show-word-limit
            disabled
            class="uploadFile"
          >
            <template slot="append">
              <i
                class="el-icon-circle-close"
                @click="handleClear"
                v-show="form.localFileName"
              />
              <el-upload
                action="#"
                :on-change="beforeUploadFunction"
                :auto-upload="false"
                :show-file-list="false"
                accept=".sld"
              >
                <div slot="trigger" class="selectLocale">本地选择</div>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="代码解析："
          label-position="left"
          prop="styleContent"
        >
          <div class="CodeEditor">
            <div class="CodeEditor-header">
              代码编辑器
              <div class="CodeEditor-header-icon">
                <div>
                  <list-tooltip content="上传文件">
                    <label
                      class="fileInput"
                      v-if="addStyleDialogVisible.title == '编辑代码样式'"
                      ><i
                        slot="trigger"
                        class="el-icon-upload2"
                        style="color: #fff" />
                      <input
                        type="file"
                        @change="handleFileChange"
                        accept=".sld"
                    /></label>
                  </list-tooltip>
                </div>
                <div @click="handelCode">
                  <list-tooltip content="大屏编辑">
                    <i class="el-icon-full-screen" />
                  </list-tooltip>
                </div>
              </div>
            </div>
            <el-input v-model="form.styleContent" type="textarea" :rows="7">
            </el-input>
          </div>
        </el-form-item>
        <!-- 底部按钮 -->
        <el-form-item>
          <div class="form-btn-group">
            <Button class="form-btn cancel" @click="handleClose">取消</Button>
            <Button class="form-btn confirm" @click.native="confirm"
              >确定</Button
            >
          </div>
        </el-form-item>
      </el-form>
      <!-- 代码编辑窗口标题 -->
      <div
        class="code"
        v-if="codeDialogVisible"
        :style="{ background: [codeColor ? '#ffffff' : 'rgb(199, 237, 204)'] }"
      >
        <!-- 代码编辑窗口标题 -->
        <div class="code-header">
          <div>代码在线编辑器</div>
          <div class="code-header-icon">
            <list-tooltip :content="codeColor ? '护眼模式' : '恢复'">
              <i
                :class="codeColor ? 'el-icon-view' : 'el-icon-sunny'"
                @click="codeColor = !codeColor"
              />
            </list-tooltip>
            <list-tooltip content="关闭">
              <i class="el-icon-copy-document" @click="handelCodeClose" />
            </list-tooltip>
          </div>
        </div>
        <!-- 代码编辑窗口组件-->
        <div class="code-centent">
          <code-mirror
            ref="changeSql"
            :valueC="form.styleContent"
            @handleContent="handleContent"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Button from 'c/basic/Button';
import axios from 'axios';
import ListTooltip from 'c/basic/ListTooltip.vue';
import CodeMirror from 'c/workbench/CodeMirror.vue';
// 引入表单校验规则
import { userRulesMixin } from 'p/mixin.js';
export default {
  name: 'AddStyle',
  mixins: [userRulesMixin],
  components: {
    Button,
    ListTooltip,
    CodeMirror
  },
  data() {
    return {
      form: {
        // 名称
        name: '',
        // 说明
        description: '',
        // 导入文件标题
        localFileName: '',
        // 代码解析内容
        styleContent: '',
        // 分类id
        categoryId: '',
        id: ''
      },

      // 存放用于验证是否改动的临时代码
      styleContentTemporary: '',
      // 分类数据
      categoryOptions: [],
      // 代码编辑窗口大屏
      codeDialogVisible: false,
      // 代码编辑窗口背景
      codeColor: true,
      // 代码语法类型
      language: 'JavaScript'
    };
  },
  computed: {
    addStyleDialogVisible: {
      get() {
        return this.$store.state.materialLibrary.addStyleDialogVisible;
      },
      set(val) {
        this.$store.commit('materialLibrary/setAddStyleDialogVisible', val);
      }
    }
  },

  methods: {
    // 关闭代码编辑窗口
    handelCodeClose() {
      this.$refs.changeSql.handleContent();
      this.codeDialogVisible = false;
    },
    // 打开代码编辑窗口
    handelCode() {
      this.form.styleContent = this.form.styleContent + '';
      this.codeDialogVisible = true;
    },
    handleContent(val) {
      this.form.styleContent = val;
    },
    // 获取分类数据
    getStyleList() {
      const params = {
        type: 4,
        order: 1,
        selectSystem: 0
      };
      this.$service.materialLibrary
        .styleResourceCategoryAll(params)
        .then(res => {
          if (res.data.status === 200) {
            this.categoryOptions = res.data.data;
            if (this.form.categoryId) return;
            this.form.categoryId = res.data.data[0].id;
          }
        });
    },
    // 取消
    handleClose() {
      if (this.addStyleDialogVisible.title == '编辑代码样式') {
        if (this.form.styleContent == this.styleContentTemporary) {
          this.addStyleDialogVisible.status = false;
          this.styleContentTemporary = '';
        } else {
          this.$confirm(
            '系统检测到您的代码内容有更改，是否保存？',
            '温馨提示',
            {
              confirmButtonText: '保存',
              cancelButtonText: '取消',
              type: 'warning',
              customClass: 'del',
              distinguishCancelAndClose: true
            }
          )
            .then(() => {
              this.confirm();
              this.styleContentTemporary = '';
            })
            .catch(action => {
              if (action === 'cancel') {
                this.styleContentTemporary = '';
                this.form.styleContent = '';
                this.$store.commit(
                  'materialLibrary/setAddStyleDialogVisible',
                  ''
                );
              }
            });
        }
      } else {
        this.addStyleDialogVisible.status = false;
      }
    },
    // 提交表单
    confirm() {
      this.$refs.ruleFormRef.validate(valid => {
        if (valid) {
          let title =
            this.addStyleDialogVisible.title != '编辑代码样式'
              ? 'styleResourceAdd'
              : 'styleResourceEidt';
          const params = this.form;
          this.$service.materialLibrary[title](params).then(res => {
            if (res.data.status === 200) {
              this.$message.success(res.data.msg);
              this.$emit('refresh');
              this.$store.commit(
                'materialLibrary/setAddStyleDialogVisible',
                ''
              );
            }
          });
        }
      });
    },
    //上传文件大小和格式校验
    beforeUploadFunction(file) {
      const _name = file.name;
      const _index = _name.lastIndexOf('.');
      const _imgType = _name.substring(_index);
      const _isLt1M = file.size / 1024 / 1024 < 1;
      if (_imgType == '.sld' && _isLt1M) {
        this.form.localFileName = file.name;
        var reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            //打印文件内容
            this.form.styleContent = reader.result;
          }
        };
        reader.readAsText(file.raw);
      } else {
        this.$message('请选择1M以内，SLD格式文件');
      }
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file.name.indexOf('.sld') != -1 && file.size < 1024 * 1024) {
        var reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            //打印文件内容
            this.form.styleContent = reader.result;
          }
        };
        reader.readAsText(event.target.files[0]);
      } else {
        this.$message('请选择1M以内，SLD格式文件');
      }
      event = '';
    },
    // 样式文件内容解析
    openFile(url) {
      axios
        .get(url, {
          baseURL: window.losIp
        })
        .then(res => {
          this.form.styleContent = res.data;
          this.styleContentTemporary = res.data;
        });
    },
    //清空样式文件内容
    handleClear() {
      this.form.localFileName = '';
      this.form.styleContent = '';
    }
  },
  watch: {
    addStyleDialogVisible: {
      immediate: true,
      handler(data) {
        if (data.data) {
          const { name, description, categoryId, id } = JSON.parse(
            JSON.stringify(data.data)
          );
          this.form.name = name;
          this.form.description = description;
          this.form.categoryId = categoryId;
          this.form.id = id;
          this.openFile(JSON.parse(JSON.stringify(data.data.url)));
          this.getStyleList();
        } else if (data.status) {
          this.form = {
            name: '',
            description: '',
            localFileName: '',
            styleContent: '',
            categoryId: JSON.parse(JSON.stringify(data.categoryId)),
            id: ''
          };
          this.getStyleList();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-input {
  &__inner {
    height: 40px;
    &:focus {
      border: 1px solid #507cff;
    }
  }
  .el-icon-circle-close {
    position: absolute;
    margin: 10px 0 0 -30px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
  .el-icon-arrow-up:before {
    font-size: 20px;
    &:focus {
      border: 1px solid #507cff;
    }
  }
  &-group__append {
    border: 1px solid #507cff !important;
    width: 80px;
    background: #507cff;
    padding: 0;
    border-radius: 0px 6px 6px 0px;
  }
}
/deep/.el-select .el-input.is-focus .el-input__inner {
  border: 1px solid #507cff !important;
}
.selectLocale {
  width: 80px;
  height: 38px;
  color: #fff;
  line-height: 40px;
}
.form-btn-group {
  margin-top: 0 !important;
}
.uploadFile {
  /deep/.el-input__inner {
    padding-right: 35px;
  }
}

.CodeEditor {
  width: 310px;
  height: 206px;
  background: #eeeeee;
  border: 1px solid #507cff;
  border-radius: 4px;
  &-header {
    width: 310px;
    height: 26px;
    background: #507cff;
    font-size: 14px;
    text-align: center;
    line-height: 26px;
    color: #fff;
    &-icon {
      display: inline-block;
      position: absolute;
      width: 175px;
      font-size: 16px;

      .el-icon-full-screen {
        position: absolute;
        top: 4px;
        left: 98px;
        cursor: pointer;
      }
      .el-icon-upload2 {
        color: #fff;
        cursor: pointer;
      }
    }
    // 上传按钮
    .fileInput {
      display: block;
      width: 20px;
      height: 20px;
      margin-left: 75px;
      cursor: pointer;
      input {
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
  }
  /deep/.el-textarea__inner {
    border: none;
    background-color: transparent;
    height: 180px;
    resize: none;
  }
}

.code {
  width: 1780px;
  height: 800px;
  margin: 65px 70px;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 0;
  border-radius: 5px;
  background: #404c6cdb;
  overflow: hidden;
  &-header {
    height: 50px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 20px;
    align-items: center;
    border-bottom: 1px solid #3333334b;
    &-icon {
      cursor: pointer;
      .el-icon-copy-document {
        margin-left: 10px;
      }
    }
  }
  &-centent {
    height: 750px;
    overflow: hidden;
    overflow-y: auto;
    /deep/.CodeMirror {
      height: 750px;
    }
  }
}
</style>
