<template>
  <div class="in-coder-panel">
    <textarea ref="mycode" v-model="code" class="text_cls"></textarea>
  </div>
</template>

<script>
import 'codemirror/theme/ambiance.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/fold/foldgutter.css';

const CodeMirror = require('codemirror/lib/codemirror');
require('codemirror/addon/edit/matchbrackets');
require('codemirror/addon/selection/active-line');
require('codemirror/addon/hint/show-hint');
require('codemirror/addon/fold/foldgutter');

require('codemirror/addon/fold/xml-fold');
require('codemirror/mode/xml/xml');
// require('codemirror/addon/hint/xml-hint');
// require('codemirror/addon/hint/css-hint');
// require('codemirror/addon/hint/javascript-hint');
// require('codemirror/addon/hint/html-hint');
export default {
  name: 'in-coder',
  props: {
    // 外部传入的内容，用于实现双向绑定
    valueC: {
      type: String,
      default: null
    }
  },
  watch: {
    valueC(newVal) {
      this.editor.setValue(newVal);
    }
  },
  data() {
    return {
      code: '',
      editor: null,
      content: ''
    };
  },
  mounted() {
    // 初始化
    this.code = this.valueC;
    this._initialize();
  },
  methods: {
    //父组件调用清空的方法
    resetData() {
      this.editor.setValue('');
    },
    // 初始化
    _initialize() {
      // let theme = 'ambiance'//设置主题，不设置的会使用默认主题
      this.editor = CodeMirror.fromTextArea(this.$refs.mycode, {
        // 选择对应代码编辑器的语言，我这边选的是数据库，根据个人情况自行设置即可
        mode: 'application/xml',
        indentWithTabs: true,
        autofocus: false, // 设置焦点
        lineWrapping: true, // 自动换行
        styleActiveLine: true, // 选中行高亮
        smartIndent: false, // 默认为true，设置为false，换行的时候上下文的缩进没有，从该行的首位开始
        lineNumbers: true,
        indentUnit: 2, // 缩进两字符
        matchBrackets: true, // 匹配括号
        lint: true, // 代码出错提醒
        extraKeys: {
          // 触发提示按键
          Ctrl: 'autocomplete'
        },
        hintOptions: {
          // 自定义提示选项
          completeSingle: true, // 当匹配只有一项的时候是否自动补全
          tables: {} // 代码提示
        }
      });
      this.editor.setValue(this.value || this.code);
      // 支持双向绑定
      this.editor.on('change', coder => {
        this.code = coder.getValue();
      });
      this.editor.on('inputRead', () => {
        this.editor.showHint();
      });
    },
    handleContent() {
      this.$emit('handleContent', this.code);
    }
  }
};
</script>

<style lang="less">
.CodeMirror {
  background: transparent;
  z-index: 0 !important;
}
.in-coder-panel {
  border-radius: 5px;
  flex-grow: 1;
  display: flex;
  position: relative;
  //.text_cls {
  //}
  .cm-variable {
    font-size: 18px;
  }
}
.CodeMirror {
  flex-grow: 1;
  z-index: 1;
}
.CodeMirror-code {
  line-height: 19px;
}

.code-mode-select {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  max-width: 130px;
}
</style>
